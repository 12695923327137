import React from 'react';
import { FaRegSadTear } from 'react-icons/fa';

const Error = ({ statusCode }) => (
  <div style={{ height: '100vh' }}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>
          <FaRegSadTear size={80} />
        </div>
        <div style={{ marginTop: 30 }} />
        <p style={{ color: 'white', fontWeight: 'bold', fontSize: '5em' }}>
          404
        </p>
        <p style={{ color: 'white' }}>
          The resource requested could not be found on this server!
        </p>
      </div>
    </div>
  </div>
);

// Error.getInitialProps = ({ res, err }) => {
//   const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
//   return { statusCode };
// };

export default Error;
